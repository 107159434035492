import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useQuery } from '@apollo/client';
import { ThemeContext } from 'styled-components';

import { LANGUAGES } from '../../_common/queries';
import useFeatureFlag from '../../_common/utils/hooks/useFeatureFlag';
import useFormInput from '../../_common/utils/hooks/useFormInput';
import { getLocale, Locale, setLocale } from '../../_common/utils/locale';
import { LanguagesQuery } from '../../types/api';

export function ProviderHeader() {
  const { i18n } = useTranslation();
  const client = useApolloClient();
  const { providerLogo } = useContext(ThemeContext);

  const languageSelectEnabled = useFeatureFlag('language_picker.enabled', true);
  const languageSelect = useFormInput(getLocale() ?? undefined);

  const { data: languageData } = useQuery<LanguagesQuery>(LANGUAGES);
  const languages = languageData?.languages ?? [];

  useEffect(() => {
    if (
      languageSelect.value !== undefined &&
      languageSelect.value !== getLocale()
    ) {
      setLocale(languageSelect.value as Locale);
      i18n
        .changeLanguage(languageSelect.value, () => {
          client.reFetchObservableQueries().catch(console.error);
        })
        .catch(console.error);
    }
  }, [languageSelect.value]);

  if (
    providerLogo == null &&
    (!languageSelectEnabled ||
      (languageSelectEnabled && languages.length === 0))
  ) {
    return null;
  }

  return (
    <div className="flex items-center justify-end px-3 py-2 w-full bg-gray-100">
      {languageSelectEnabled && languageData && (
        <select
          className="outline-none mr-4 px-2"
          onChange={languageSelect.onChange}
          value={languageSelect.value}
        >
          {languages.map(({ label, locale }) => (
            <option key={locale} value={locale}>
              {label}
            </option>
          ))}
        </select>
      )}
      {providerLogo != null && (
        <img alt="provider logo" className="h-6" src={providerLogo} />
      )}
    </div>
  );
}
