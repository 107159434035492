import * as yup from 'yup';

import i18n from '../../I18n';
import { UpdateUserInput } from '../../types/api';

export const userValidationSchema = yup
  .object()
  .shape<UpdateUserInput & { confirmPassword?: string }>({
    id: yup.string().required(), // will not be editable
    firstName: yup
      .string()
      .required(
        i18n.t('errors.required_error', { label: i18n.t('user:first_name') }),
      ),
    lastName: yup
      .string()
      .required(
        i18n.t('errors.required_error', { label: i18n.t('user:last_name') }),
      ),
    email: yup
      .string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        i18n.t('errors.invalid_error', { label: i18n.t('user:email') }),
      )
      .required(
        i18n.t('errors.required_error', { label: i18n.t('user:email') }),
      ),
    password: yup
      .string()
      .test(
        'optional-password',
        i18n.t('user:password_length'),
        (value) => value == null || value.length === 0 || value.length >= 8,
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], i18n.t('user:match_passwords')),
  });
