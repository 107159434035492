import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router';
import styled from 'styled-components';

import PrimaryButton, { PrimaryBlackButton } from '../../_common/button';
import ErrorMessage from '../../_common/fields/_common/errorMessage';
import InputField from '../../_common/fields/InputField';
import StringField from '../../_common/fields/StringField';
import useFeatureFlag from '../../_common/utils/hooks/useFeatureFlag';
import useQueryParams from '../../_common/utils/hooks/useQueryParams';
import useUpdateTranslations, {
  Module,
} from '../../_common/utils/hooks/useUpdateTranslations';
import { getToken } from '../../_common/utils/token';

import {
  handleFetchSSOUrl,
  handleLogin,
  handleValidate,
  LoginInput,
} from './utils';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  width: 75vw;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
`;

const LoginButton = styled(PrimaryButton)`
  width: 100%;
`;

const SSOButton = styled(PrimaryBlackButton)`
  width: 100%;
`;

export default function Login() {
  const { t } = useTranslation();
  const { state: { from = '/' } = {}, search } = useLocation();
  const { errorCode, errorMessage, id } = useQueryParams();
  const [SSOUrl, setSSOUrl] = useState<string | undefined>('/login');
  const ssoEnabled = useFeatureFlag('sso.enabled', true);

  const assertError = useMemo(
    () =>
      errorMessage
        ? `${t(`login:login_form.${errorMessage}`, { code: errorCode })}`
        : '',
    [errorMessage, errorCode],
  );

  async function handleFormSubmit(values: LoginInput) {
    return await handleLogin(values, search);
  }

  useEffect(() => {
    (async function () {
      if (ssoEnabled) {
        const result = await handleFetchSSOUrl(id);

        setSSOUrl(result);
      }
    })();
  }, [ssoEnabled, id]);

  useUpdateTranslations(Module.LOGIN);

  return (
    <Wrapper>
      <Form onSubmit={handleFormSubmit} validate={handleValidate}>
        {({
          handleSubmit,
          submitError,
          submitSucceeded,
          submitting,
          values: { email, password },
        }) => (
          <LoginForm onSubmit={handleSubmit}>
            {(submitSucceeded || getToken()) && (
              <Redirect
                to={
                  from + search.replace(/&?(errorMessage|errorCode)=[^&]*/g, '')
                }
              />
            )}
            <InputField label={t('login:login_form.email')} name="email">
              {(input) => <StringField {...input} type="email" />}
            </InputField>

            <InputField label={t('login:login_form.password')} name="password">
              {(input) => <StringField {...input} type="password" />}
            </InputField>

            <div className="mt-4 space-y-4">
              <LoginButton
                disabled={!email || !password || submitting}
                type="submit"
              >
                {t('login:login_form.login')}
              </LoginButton>
              {ssoEnabled && (
                <SSOButton disabled={SSOUrl === '/login'} href={SSOUrl}>
                  {t('login:login_form.sso')}
                </SSOButton>
              )}
            </div>

            {(submitError || assertError) && (
              <ErrorMessage>{submitError || assertError}</ErrorMessage>
            )}
          </LoginForm>
        )}
      </Form>
    </Wrapper>
  );
}

Login.displayName = 'Login';
