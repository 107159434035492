import React from 'react';

import { InstanceHeader } from './InstanceHeader';
import { ProviderHeader } from './ProviderHeader';

export function ApplicationHeader() {
  return (
    <header>
      <ProviderHeader />
      <InstanceHeader />
    </header>
  );
}
