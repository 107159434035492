import React from 'react';

import Avatar from '../../_common/Avatar';

interface Props {
  firstName: string;
  lastName: string;
  onClick?: () => void;
}

export function User(props: Props) {
  const { firstName, lastName, onClick } = props;

  return (
    <div
      className={`flex space-x-2 items-center ${
        onClick !== undefined ? 'cursor-pointer' : 'cursor-default'
      }`}
      onClick={onClick}
    >
      <Avatar firstName={firstName} lastName={lastName} />
      <span className="hidden md:flex">
        {firstName}&nbsp;{lastName}
      </span>
    </div>
  );
}
