import React, { useEffect, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import PrimaryButton from '../../_common/button';
import ErrorMessage from '../../_common/fields/_common/errorMessage';
import InputField from '../../_common/fields/InputField';
import StringField from '../../_common/fields/StringField';

const FormWrapper = styled.form`
  border: 1px solid ${({ theme }) => theme.colors.BORDER};
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 0.5rem 0;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.SUCCESS};
  margin-left: 1rem;
`;

export default function UserForm({
  form,
  handleSubmit,
  modified,
  submitError,
  submitFailed,
  submitSucceeded,
  submitting,
  valid,
}: FormRenderProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (submitFailed || submitSucceeded) {
      form.batch(() => {
        form.change('password', '');
        form.change('confirmPassword', '');
        form.blur('password');
        form.blur('confirmPassword');
        form.resetFieldState('password');
        form.resetFieldState('confirmPassword');
      });
    }
  }, [submitFailed, submitSucceeded]);

  const dirty = useMemo(
    () =>
      modified !== undefined
        ? Object.values(modified).some((value) => value)
        : false,
    [modified],
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <InputField label={t('user:first_name')} name="firstName">
        {(input) => <StringField {...input} type="text" />}
      </InputField>
      <InputField label={t('user:last_name')} name="lastName">
        {(input) => <StringField {...input} type="text" />}
      </InputField>
      <InputField label={t('user:email')} name="email">
        {(input) => <StringField {...input} type="email" />}
      </InputField>
      <InputField label={t('user:password')} name="password">
        {(input) => <StringField {...input} type="password" />}
      </InputField>
      <InputField label={t('user:confirm_password')} name="confirmPassword">
        {(input) => <StringField {...input} type="password" />}
      </InputField>

      <ButtonContainer>
        <SubmitButton
          disabled={submitting || !dirty || !valid}
          loading={submitting}
          type="submit"
        >
          {t('button.save')}
        </SubmitButton>
        {submitSucceeded && !submitting && !dirty && (
          <Icon icon={['fas', 'check']} />
        )}
      </ButtonContainer>
      {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
    </FormWrapper>
  );
}
