import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router';
import { Redirect, RouteProps } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import useFeatureFlag from '../../../_common/utils/hooks/useFeatureFlag';
import useQueryParams from '../../../_common/utils/hooks/useQueryParams';
import { getDecodedToken } from '../../../_common/utils/token';
import { BOOKING_PATH } from '../../../utils/constants';
import { getLoginUrl, handleFetchSSOUrl } from '../../login/utils';

interface Props extends RouteProps {
  accessLevel?: number;
}

export default function PrivateRoute({ accessLevel, ...rest }: Props) {
  const token = getDecodedToken();
  const history = useHistory();
  const { id } = useQueryParams();
  const ssoEnabled = useFeatureFlag('sso.enabled', true);

  async function redirect() {
    const redirectState = { from: window.location.pathname };

    try {
      if (ssoEnabled) {
        const url = await handleFetchSSOUrl(id);

        return window.location.replace(url);
      } else {
        return history.push(getLoginUrl(id), redirectState);
      }
    } catch (error) {
      Sentry.captureException(error);

      return history.push(getLoginUrl(id), redirectState);
    }
  }

  useEffect(() => {
    if (!token) {
      redirect();
    }
  });

  if (
    accessLevel !== undefined &&
    token?.accessLevel !== undefined &&
    token.accessLevel > accessLevel
  ) {
    return <Redirect to={BOOKING_PATH} />;
  }

  return <Route {...rest} />;
}
