import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ApplicationHeader } from '../../components/ApplicationHeader/Header';

interface ErrorRouteProps {
  error?: string;
}

export default function Error({
  location,
}: RouteComponentProps<{}, StaticContext, ErrorRouteProps>) {
  const { t } = useTranslation();

  return (
    <>
      <ApplicationHeader />
      <div className="flex flex-col items-center justify-center w-full h-screen">
        <FontAwesomeIcon icon={['fas', 'times']} size="6x" />
        <h3 className="mt-8">
          {location?.state?.error ?? t('errors.technical')}
        </h3>
      </div>
    </>
  );
}
