import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import styled from 'styled-components';

import Spinner, { SpinnerSizes } from '../../_common/Spinner';
import { validateSchema } from '../../_common/utils/form';
import { AuthMethod, getDecodedToken } from '../../_common/utils/token';
import { ApplicationHeader } from '../../components/ApplicationHeader/Header';
import breakPoints from '../../global/theme/breakPoints';

import { UPDATE_USER } from './mutations';
import { USER } from './queries';
import UserForm from './UserForm';
import { userValidationSchema } from './utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  margin: 0.5rem auto;
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
  max-width: 90%;
  ${breakPoints.large} {
    max-width: 85%;
  }

  ${breakPoints.extraLarge} {
    max-width: 65%;
  }
`;

const CenteredSpinner = styled(Spinner)`
  margin: 3rem auto;
`;

export default function User() {
  const { t } = useTranslation();
  const token = getDecodedToken();

  const { data, loading } = useQuery(USER, {
    variables: { id: token?.id },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['UserQuery'],
  });

  const handleValidate = useCallback(
    async (values) => validateSchema(userValidationSchema, values),
    [],
  );

  const handleSubmit = useCallback(
    async ({ email, firstName, id, lastName, password }) => {
      try {
        await updateUser({
          variables: {
            input: {
              id,
              firstName: firstName || undefined,
              lastName: lastName || undefined,
              password: password || undefined,
              email: email || undefined,
            },
          },
        });

        return undefined;
      } catch {
        return { [FORM_ERROR]: t('user:submit_fail') };
      }
    },
    [],
  );

  if (token?.method !== AuthMethod.PLAIN) {
    return null;
  }

  return (
    <Wrapper>
      {/* TODO: move up the application so we don't have to import this on 3 places */}
      <ApplicationHeader />
      <Content>
        {loading ? (
          <CenteredSpinner size={SpinnerSizes.LARGE} />
        ) : (
          <Form
            initialValues={data?.user ?? token}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true,
              submitError: true,
              modified: true,
              submitSucceeded: true,
              values: true,
              submitFailed: true,
              valid: true,
            }}
            validate={handleValidate}
          >
            {(props) => <UserForm {...props} />}
          </Form>
        )}
      </Content>
    </Wrapper>
  );
}
