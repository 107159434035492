import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Heading1, Heading3 } from '../../_common/Typography';
import useUpdateTranslations, {
  Module,
} from '../../_common/utils/hooks/useUpdateTranslations';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function () {
  const { t } = useTranslation();

  useUpdateTranslations(Module.NOT_FOUND);

  return (
    <Container>
      <Heading1>{t('notfound:title')}</Heading1>
      <Heading3>{t('notfound:message')}</Heading3>
    </Container>
  );
}
