import * as Sentry from '@sentry/browser';
import { FORM_ERROR } from 'final-form';
import * as yup from 'yup';

import { API_URL } from '../..';
import { validateSchema } from '../../_common/utils/form';
import { setToken } from '../../_common/utils/token';
import i18n from '../../I18n';

const SAML_URL = `${process.env.API_HOST}:${process.env.API_PORT}${process.env.API_PATH}/auth/saml/login`;

export interface LoginInput {
  email: string;
  password: string;
}

export function getLoginUrl(id?: string) {
  if (id) {
    return `/login?id=${id}`;
  }

  return '/login';
}

export async function handleLogin(values: LoginInput, search: string) {
  try {
    const response = await fetch(`${API_URL}/login${search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    if (response.status !== 200) {
      return { [FORM_ERROR]: i18n.t('login:login_form.login_failed') };
    }

    setToken(await response.text());

    return;
  } catch (error) {
    return {
      [FORM_ERROR]: `${i18n.t('login:login_form.login_failed')}: ${
        error.message
      }`,
    };
  }
}

export async function handleFetchSSOUrl(id?: string) {
  const relayStateQueryString = id !== undefined ? `?relayState=${id}` : '';
  const url = `${SAML_URL}${relayStateQueryString}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return await response.text();
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra('sso_url', url);
      Sentry.captureException(error);
    });

    return getLoginUrl(id);
  }
}

export async function handleValidate(values: LoginInput) {
  const schema = yup.object().shape<LoginInput>({
    email: yup.string().required(),
    password: yup.string().required(),
  });

  return validateSchema(schema, values);
}
