export const DEFAULT_BRAND_COLOR = '#DC0000';

const TAILWIND_PROPERTIES = {
  bg: 'background-color',
  border: 'border-color',
  text: 'color',
};

const TAILWIND_VARIANTS = {
  'group-hover': '.group:hover .group-hover\\:',
};

export interface BrandColors {
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
}

export function generateTailwindClasses(colors: BrandColors) {
  // TODO: does flatMap need a polyfill for IE11 ?
  const defaultCss = Object.entries(TAILWIND_PROPERTIES).flatMap(
    ([key, cssProperty]) =>
      Object.keys(colors).map(
        // TODO: Make this order independent (e.g. if brandDarkest gets passed first, bg-brand-300 will be darkest)
        (index) =>
          `.${key}-brand-${index} { ${cssProperty}: ${colors[index]} }`,
      ),
  );

  const variantsCss = Object.entries(TAILWIND_PROPERTIES).flatMap(
    ([key, cssProperty]) =>
      Object.values(TAILWIND_VARIANTS).flatMap((variant) =>
        Object.keys(colors).map(
          // TODO: Make this order independent (e.g. if brandDarkest gets passed first, bg-brand-300 will be darkest)
          (index) =>
            `${variant}${key}-brand-${index} { ${cssProperty}: ${colors[index]} }`,
        ),
      ),
  );

  return [...defaultCss, ...variantsCss].join('\n');
}
